.column {
  &__icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  &--left {
    .column-title {
      justify-content: left;
    }
  }

  &--image {
    background: var(--background-blue);
    padding: 0 !important;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--background-blue) !important;
    }

    image,
    svg {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    height: 34px;
    justify-content: center;

    span {
      white-space: nowrap;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}

.ant-table-row {
  &:hover {
    .column--image {
      background: var(--background-blue) !important;
    }
  }
}

th.ant-table-cell:hover {
  .sort {
    display: block;
  }

  .filter__dropdown {
    display: block;
  }
}
.ant-table-selection-column {
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 6px;
    left: 6px;
    width: 9px;
    height: 2px;
    background-color: #fff;
  }
}
