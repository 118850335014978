.app {
  &__container {
    width: 100%;
    height: calc(100% - 70px);
    padding: 0 10%;
    overflow: auto;

    background: var(--background-gray-light);
  }
}
