.filter {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;

    background: var(--background-white);
    box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.25), 0px 1px 5px rgba(152, 162, 179, 0.25);

    margin-bottom: 48px;
  }

  &__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    width: 100%;
    margin: 16px 0 0 0;

    &--no-results {
      color: var(--status-red);
      position: relative;

      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      letter-spacing: 0.1px;

      display: flex;
      padding-bottom: 16px;

      &::before {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0px;
        border-top: 1px dashed #dadada;
        content: '';
      }

      span {
        padding-right: 5px;
      }
    }

    &--resent,
    &--results-count {
      font-family: Lato;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      letter-spacing: 0.1px;
      color: var(--table-gray);

      padding: 8px 0;

      span {
        padding-right: 5px;
        text-transform: capitalize;
      }
    }

    &-icon {
      position: absolute;
      right: 10px;
      color: rgba(0, 0, 0, 0.45);
    }

    &-label {
      color: var(--table-gray);
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      padding-right: 8px;
    }

    &-placeholder {
      display: flex;
      align-items: center;

      &-tag {
        margin-left: 6px;
        background: var(--background-white);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        border: 1px dashed #dadada;
        border-radius: 14px;
        width: min-content;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--disable-gray);

        svg {
          margin-left: 6px;
        }
      }
    }

    &--active {
      &::before {
        position: absolute;
        bottom: -5px;
        z-index: 1;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: var(--main-blue);
        content: '';
      }
    }

    &--error {
      .ant-select-selector {
        border-color: var(--stroke-gray) !important;
        box-shadow: none !important;
      }
      &::before {
        position: absolute;
        bottom: -5px;
        z-index: 1;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: var(--status-red);
        content: '';
      }
    }

    &-dropdown {
      background: #ffffff;
      min-width: 300px;

      box-shadow: 0px 12px 24px rgba(65, 78, 101, 0.1), 0px 6px 12px rgba(65, 78, 101, 0.1), 0px 4px 4px rgba(65, 78, 101, 0.1),
        0px 2px 2px rgba(65, 78, 101, 0.1);
      border-radius: 2px;

      .ant-select-item-option-selected {
        display: none;
      }

      .rc-virtual-list-holder {
        max-height: inherit !important;
      }

      .rc-virtual-list-scrollbar {
        display: none !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-option {
      display: flex;
      padding: 8px;
      position: relative;
      height: 92px;

      &:hover {
        background-color: var(--background-blue-light);
      }

      &:last-child {
        &::before {
          height: 0;
        }
      }

      &::before {
        position: absolute;
        bottom: 0;
        z-index: 1;
        right: -8px;
        width: calc(100% + 16px);
        height: 2px;
        background-color: var(--background-gray);
        content: '';
      }

      img {
        border-radius: 4px;
      }

      &--info {
        margin: 0 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: -webkit-fill-available;
        width: -moz-fill-available;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;

          color: var(--main-gray);

          text-transform: capitalize;
        }

        div {
          span {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;

            align-items: center;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            color: var(--table-gray);

            &:first-child {
              margin-right: 30px;
            }
          }
        }
      }
    }

    &-options {
      border: 2px solid var(--background-gray);
      border-radius: 2px;

      max-height: calc(92px * 6);
      overflow-x: hidden;
      overflow-y: auto;

      &--container {
        background-color: #ffffff !important;
      }
    }

    .ant-select {
      width: inherit;
      height: 42px;
      border: 0;

      &-selection-placeholder {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;

        color: var(--disable-gray);
      }

      &-selector {
        width: 100%;
      }
    }

    .ant-input-affix-wrapper {
      border: 1px solid var(--stroke-gray);
    }
  }

  &__dropdown {
    display: none;
    margin: auto;
    margin-right: 0 !important;
    width: 28px !important;
    height: 28px !important;
    background: transparent;
    border: none;

    &--visible {
      display: block;
    }
  }

  &__cards {
    display: flex;
    border: 1px solid var(--stroke-gray);
    border-right: none;
    width: 100%;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
}

.card {
  &-tag {
    background: #ffffff;

    box-shadow: 0px 12px 24px rgba(65, 78, 101, 0.1), 0px 6px 12px rgba(65, 78, 101, 0.1), 0px 4px 4px rgba(65, 78, 101, 0.1),
      0px 2px 2px rgba(65, 78, 101, 0.1);
    border-radius: 2px;

    &__header {
      padding: 16px 16px 0 16px;
    }

    &__title {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      color: var(--table-gray);
    }

    &__select-all {
      background: transparent;
      border: none;

      padding: 0 4px;
      margin: 0px 8px;

      span {
        text-decoration: underline;
        color: var(--disable-gray);
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding: 0 16px 16px 16px;

      &--language {
        @extend .card-tag__options;

        flex-direction: row;
        flex-wrap: wrap;

        max-width: 300px;
      }
    }

    &__option {
      border-radius: 14px;
      margin: 2px;
      padding: 4px 8px;
      color: var(--main-gray);

      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      text-transform: capitalize;

      svg {
        color: var(--main-gray);
      }

      i {
        margin: 0 auto;
      }
    }

    &--language {
      @extend .card-tag;

      position: relative;
      &::before {
        position: absolute;
        top: 0;
        right: 16px;
        width: calc(100% - 32px);
        height: 0px;
        border-top: 1px dashed #dadada;
        //background-color: var(--main-blue);
        content: '';
      }
      // border-top: 1px dashed #dadada;
    }
  }

  &-checkbox {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 25%;

    @media (max-width: 1024px) {
      width: 50%;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: var(--stroke-gray);
      content: '';
    }

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: var(--stroke-gray);
      content: '';
    }

    &__tooltip {
      .ant-tooltip-inner,
      .ant-tooltip-arrow-content {
        border-radius: 4px;
        background: #fff;
        color: var(--main-gray);
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      height: 40px;
      padding: 16px 16px 0 16px;

      button {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-evenly;

        padding: 0;
        width: 65px;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;

          margin: 0;
          color: var(--disable-gray);
        }
      }
    }

    &__title {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;

      color: var(--table-gray);

      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
      overflow: hidden;

      &--with-btn {
        @extend .card-checkbox__title;

        width: calc(100% - 65px);
      }
    }

    &__checkbox {
      padding: 4px 0;
    }

    &__search {
      width: calc(100% - 32px);
      margin: 6px 16px 0 16px;
      height: 50;
      padding: 0;
      position: relative;

      overflow: hidden;
      display: flex;

      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;

      .card-select {
        width: 100%;

        &::before {
          height: 0;
        }
      }

      // &-select {
      //   width: 100% !important;
      //   height: 40px !important;
      //   border-radius: 2px;
      // }

      // &-icon {
      //   position: absolute;
      //   right: 16px;
      // }

      // div.ant-select > div.ant-select-selector {
      //   width: 100%;
      //   height: 100% !important;
      //   border: none;
      // }
    }

    &__options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      max-height: 130px;

      padding: 4px 0;
      overflow-y: auto;
      overflow-x: hidden;

      &--search {
        @extend .card-checkbox__options;
        max-height: 100px;
      }

      label {
        margin-left: 16px !important;
      }
    }

    &__option {
      &-title {
        text-transform: capitalize;
      }
    }
  }

  &-select {
    display: flex;
    align-items: center;

    width: 320px;
    height: 44px;
    position: relative;

    &::before {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 320px;
      height: 3px;
      background-color: var(--main-blue);
      content: '';
    }

    &__icon {
      position: absolute;
      right: 10px;
      color: rgba(0, 0, 0, 0.45);
    }

    &__option {
      &--checked {
        background-color: var(--background-blue-light) !important;
      }
    }

    &__dropdown {
      box-shadow: 0px 12px 24px rgba(65, 78, 101, 0.1), 0px 6px 12px rgba(65, 78, 101, 0.1), 0px 4px 4px rgba(65, 78, 101, 0.1),
        0px 2px 2px rgba(65, 78, 101, 0.1);
      border-radius: 2px;

      max-height: 228px;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-select-item-option-state {
        display: none;
      }
    }

    &__checkbox {
      width: 100%;
    }

    &__selected {
      background: var(--background-white);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      border-radius: 14px;
      width: min-content;

      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: var(--disable-gray);

      svg {
        color: var(--disable-gray);
      }
    }

    &__placeholder {
      display: flex;
      align-items: center;

      &-tag {
        margin-left: 6px;
        background: var(--background-white);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        border: 1px dashed #dadada;
        border-radius: 14px;
        width: min-content;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--disable-gray);

        svg {
          margin-left: 6px;
        }
      }
    }

    .ant-select {
      width: inherit;
      // min-width: 320px;
      height: 100%;
      border: none;

      &-item-option-state {
        display: none !important;
      }

      &-selector {
        width: 100%;
        border: none;
        height: 100% !important;
      }

      &-selection {
        &-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 28px;
          padding: 4px 8px 4px 4px;
          background: var(--tags-light-gray);
        }
        &-overflow {
          max-width: calc(100% - 30px);
        }
        &-overflow-item {
          &-rest {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: var(--disable-gray);
          }
          .ant-select-selection-item {
            padding-left: 4px;
            border-radius: 12px;
          }
          .ant-checkbox {
            display: none;
          }

          .card-select__checkbox {
            margin-bottom: 2px;
          }

          .ant-select-selection-item-content {
            margin-right: -2px;
          }
        }
        &-placeholder {
          font-family: Lato;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px !important;

          color: var(--disable-gray);
        }
      }
    }
  }
}