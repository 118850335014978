body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital@0;1&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

#root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar-thumb {
  background: var(--background-gray);
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow-y: scroll;
  background: transparent;
}

.fetching {
  // position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate( -50%);
  text-align: center;
  background-color: #e5e5e3;
  margin: 0 auto;
}

.err_login {
  position: absolute;
  padding: 20px 30px;
  color: #fff;
  background-color: rgb(21, 21, 21);
  bottom: 20px;
  right: 20px;
}