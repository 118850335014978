.global-actions {
  position: fixed;
  bottom: 0;
  z-index: 1;
  left: 0;
  padding: 0 10%;

  background: #fff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1), 0px -1px 0px #f5f5f5;

  width: 100%;
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  transition: all 5s;

  .ant-checkbox-wrapper {
    text-transform: capitalize;

    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    color: var(--main-gray);
  }

  .ant-select-selector,
  .ant-select {
    height: 32px !important;
  }

  .ant-select {
    border: none;
  }

  .ant-select-selection-item {
    text-transform: capitalize;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;

    color: var(--main-gray);
  }

  div {
    display: flex;
    align-items: center;
  }

  .select-checkboxes {
    .ant-checkbox {
      display: none;
    }
    .ant-checkbox-wrapper {
      border: none;
      text-transform: uppercase;

      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: var(--disable-gray);

      &-checked {
        color: var(--main-blue);
      }

      span {
        text-decoration: underline;
      }
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background: var(--main-blue);
}

.global-actions {
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 7px;
    left: 7px;
    width: 9px;
    height: 2px;
    background-color: #fff;
  }
}
