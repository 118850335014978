.tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  // margin-bottom: 48px;

  background: var(--background-white);
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.25), 0px 1px 5px rgba(152, 162, 179, 0.25);

  .ant-tabs-nav {
    height: 48px;
    width: 100%;
    margin: 0;
    box-shadow: inset 0px -1px 0px #c7c7c5;
  }

  .ant-tabs-content-holder {
    width: -webkit-fill-available;
    width: -moz-fill-available;
  }

  .ant-tabs-tab {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    min-width: 140px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 16px 0 8px 0;
    margin: 0;

    color: var(--table-gray);

    &-active {
      color: var(--main-blue);
    }
  }
}

.tabs__container {
  position: relative;
  margin-bottom: 48px;

  .color-meaning {
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 16px;

    &__text {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;

      margin-right: 16px;

      color: var(--disable-gray);
    }
  }
}

.tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  height: 34px;
  width: 320px;
  min-width: 320px;

  &--error {
    color: var(--status-red);
  }

  &--un-editable {
    color: var(--disable-gray);
  }

  &--draft {
    color: var(--table-gray);
  }

  &--finished {
    color: var(--status-green);
  }
}
