.sort {
  display: none;
  margin: auto 0 auto auto;
  width: 28px;
  height: 28px;
  background: transparent;
  border: none;

  .desc {
    transform: rotate(180deg);
  }

  svg {
    width: 25px;
    height: 25px;
    margin: 0;
  }
}
