.error {
  width: 100%;
  height: 100%;
  background-color: #fff2f0;

  top: 0;
  position: absolute;

  .ant-alert {
    width: 200px;
    margin: 100px auto auto auto;
    height: 100px;
  }
}
