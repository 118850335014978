.cell {
  &__checkbox {
    path {
      fill: var(--table-gray);
    }

    &--active {
      path {
        fill: var(--status-green);
      }
    }
    &--disabled {
      path {
        fill: var(--tags-dark-gray);
      }
    }

    &.ant-checkbox-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-checkbox {
      display: none;
    }

    span:last-child {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-tag {
        margin: 6px 0;
        padding: 2px 4px;
        border-radius: 14px;
        text-transform: capitalize;
        color: var(--main-gray);
      }

      .pending {
        background: var(--status-orange);
        border-color: var(--status-orange);
      }

      .aproved {
        background: var(--status-green);
        border-color: var(--status-green);
      }

      .rejected {
        background: var(--status-red);
        border-color: var(--status-red);
      }

      .archive {
        background: var(--tags-light-gray);
        border-color: var(--tags-light-gray);
      }
    }
  }

  &__last-action {
    display: flex;
    flex-direction: column;
    padding-left: 4px;

    &--date {
      color: var(--stroke-gray);
    }

    &--name {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: var(--table-gray);
    }
  }

  &__actions {
    transform: rotate(90deg);
  }
}

.cell__image {
  width: 90px;
  height: inherit;
  object-fit: contain;
}

.settings_select {
  text-align: center;
  cursor: pointer;
}

.settings_items_menu {
  text-align: center;
}

.COMPLETE, .PENDING, .REJECTED, .APROVED, .DISABLED, .statuses {
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background-color: #e5e5e3;
}

.COMPLETE {
  background-color: #e5e5e3;
}
.PENDING {
  background-color: #ffad62;
}
.REJECTED {
  background-color: #ee7886;
}
.APROVED {
  background-color: #7ed2ba;
}