.login {
  &__container {
    margin: 48px 0;
    background: var(--background-white);
    padding: 24px 24px 48px;
    box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.25), 0px 1px 5px rgba(152, 162, 179, 0.25);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 300px;

      .ant-row {
        width: 100%;
      }

      .ant-form-item-control-input-content {
        position: relative;
      }

      label {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;

        display: flex;
        align-items: center;
        letter-spacing: 0.1px;

        color: var(--table-gray);

        text-transform: capitalize;
      }

      input {
        position: relative;
        box-shadow: none !important;
        border: 1px solid var(--stroke-gray) !important;

        height: 34px;
      }

      .ant-input-affix-wrapper-focused {
        &::after {
          position: absolute;
          bottom: -2px;
          z-index: 1;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: var(--main-blue);
          content: '';
        }
      }

      .ant-input-password {
        padding: 0 12px;
        box-shadow: none !important;
        border: 1px solid var(--stroke-gray) !important;

        input {
          border: none !important;
        }
      }

      .ant-input:placeholder-shown {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;

        text-transform: capitalize;
        color: var(--disable-gray);
      }
    }
  }

  &__input {
    &--focused .ant-form-item-control-input-content {
      &::after {
        position: absolute;
        bottom: -2px;
        z-index: 1;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: var(--main-blue);
        content: '';
      }
    }
    &--with-error {
      &.login__input--focused .ant-form-item-control-input-content {
        &::after {
          position: absolute;
          bottom: -2px;
          z-index: 1;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: var(--status-red);
          content: '';
        }
      }
      .ant-input-affix-wrapper-focused::after {
        background-color: var(--status-red) !important;
      }
    }
  }

  &__error {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -24px;
    text-transform: capitalize;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.4px;

    color: var(--status-red);
  }

  &__btn {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }

    button span {
      text-transform: uppercase;
    }
  }

  &__logo {
    margin: 12px 0;
  }

  &__info {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    margin-bottom: 24px;

    text-transform: capitalize;
    color: var(--main-gray);
  }
}
