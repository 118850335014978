.header {
  &__container {
    height: 70px;
    padding: 0 10%;
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    box-shadow: 0px 4px 4px rgba(152, 162, 179, 0.25), 0px 1px 5px rgba(152, 162, 179, 0.25);
  }

  &__menu {
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 6px;

    &--icon {
      width: 180px;
      height: 38px;
    }
  }
}

.ant-breadcrumb-link {
  a {
    color: var(--table-gray);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    text-transform: capitalize;

    &:active {
      color: var(--main-blue);
    }
  }

  &:last-child {
    a {
      color: var(--main-black);
    }
  }
}
