.pagination {
  height: 90px;
  margin: 0 16px 0 auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__title {
    color: var(--disable-gray);

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;

    padding-right: 6px;
  }

  &__prev,
  &__next {
    width: 50px;
    height: 50px;
  }

  &__option {
    display: flex !important;
    align-items: center !important;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.ant-select {
  padding: 0;
  color: var(--main-gray);
  border: 1px solid var(--stroke-gray);

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;

  width: 230px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-selector {
    height: 50px !important;
    display: flex !important;
    align-items: center;
  }

  &-arrow {
    font-size: 16px;
    margin-right: 4px;
  }
}
