.title {
  padding: 40px 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    .action__container {
      button {
        margin-top: 4px;
      }
    }
  }

  &__text {
    color: var(--main-blue);

    display: flex;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  .action {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    height: 40px;
    margin: 0 8px;
    padding: 8px 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    &:active {
      color: var(--main-blue);
    }

    &--default {
      background: transparent;
      border: none;

      text-decoration: underline;
      text-transform: uppercase;
    }

    &--secodary {
      color: var(--main-blue);
      border: 2px solid var(--main-blue);

      text-transform: uppercase;
      font-weight: 600;
    }

    &--primary {
      background: var(--main-blue);
      color: var(--background-white);
      border: none;

      text-transform: uppercase;
      font-weight: 600;
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
    }

    .anticon {
      margin: 0 0 0 16px;
      font-size: 20px;

      display: flex;
      align-items: center;
    }
  }
}
