$FLAG_COUNTRY_IMG_PATH: 'src/assets/svg/country_flag/';
$FLAG_LANG_IMG_PATH: 'src/assets/svg/lang_flag/';

.flag-icon {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;

  margin-right: 5px;

  &:before {
    content: '\00a0';
  }

  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    i {
      min-width: calc(25% - 12px);
      margin: 6px 6px;
    }
  }

  &--square {
    @extend .flag-icon;
    width: 30px;
    line-height: 30px;
  }

  &--rectangle {
    @extend .flag-icon;
    width: 28px;
    max-width: 28px;
    line-height: 20px;
    border-radius: 4px;
  }

  &--circle {
    @extend .flag-icon;
    width: 23px;
    line-height: 23px;
    border-radius: 50%;
    &:before {
      content: '\00a0';
    }
  }

  &-country {
    &-af {
      background-image: url('assets/svg/country_flag/af.svg'); // Afghanistan
    }

    &-al {
      background-image: url('assets/svg/country_flag/al.svg'); // Albania
    }

    &-dz {
      background-image: url('assets/svg/country_flag/dz.svg'); // Algeria
    }

    &-as {
      background-image: url('assets/svg/country_flag/as.svg'); // American Samoa
      background-position-x: right;
    }

    &-ad {
      background-image: url('assets/svg/country_flag/ad.svg'); // Andorra
    }

    &-ao {
      background-image: url('assets/svg/country_flag/ao.svg'); // Angola
    }

    &-ai {
      background-image: url('assets/svg/country_flag/ai.svg'); // Anguilla
    }

    &-ag {
      background-image: url('assets/svg/country_flag/ag.svg'); // Antigua and Barbuda
    }

    &-ar {
      background-image: url('assets/svg/country_flag/ar.svg'); // Argentina
    }

    &-am {
      background-image: url('assets/svg/country_flag/am.svg'); // Armenia
    }

    &-aw {
      background-image: url('assets/svg/country_flag/aw.svg'); // Aruba
      background-position-x: left;
    }

    &-au {
      background-image: url('assets/svg/country_flag/au.svg'); // Australia
      background-position-x: left;
    }

    &-at {
      background-image: url('assets/svg/country_flag/at.svg'); // Austria
    }

    &-az {
      background-image: url('assets/svg/country_flag/az.svg'); // Azerbaijan
    }

    &-bs {
      background-image: url('assets/svg/country_flag/bs.svg'); // Bahamas
    }

    &-bh {
      background-image: url('assets/svg/country_flag/bh.svg'); // Bahrain
    }

    &-bd {
      background-image: url('assets/svg/country_flag/bd.svg'); // Bangladesh
    }

    &-bb {
      background-image: url('assets/svg/country_flag/bb.svg'); // Barbados
    }

    &-by {
      background-image: url('assets/svg/country_flag/by.svg'); // Belarus
      background-position-x: left;
    }

    &-be {
      background-image: url('assets/svg/country_flag/be.svg'); // Belgium
    }

    &-bz {
      background-image: url('assets/svg/country_flag/bz.svg'); // Belize
    }

    &-bj {
      background-image: url('assets/svg/country_flag/bj.svg'); // Benin
    }

    &-bm {
      background-image: url('assets/svg/country_flag/bm.svg'); // Bermuda
    }

    &-bt {
      background-image: url('assets/svg/country_flag/bt.svg'); // Bhutan
    }

    &-bo {
      background-image: url('assets/svg/country_flag/bo.svg'); // Bolivia
    }

    &-ba {
      background-image: url('assets/svg/country_flag/ba.svg'); // Bosnia and Herzegovina
    }

    &-bw {
      background-image: url('assets/svg/country_flag/bw.svg'); // Botswana
    }

    &-br {
      background-image: url('assets/svg/country_flag/br.svg'); // Brazil
    }

    &-io {
      background-image: url('assets/svg/country_flag/io.svg'); // British Indian Ocean Territory
      background-position-x: left;
      background-position-y: top;
    }

    &-vg {
      background-image: url('assets/svg/country_flag/vg.svg'); // British Virgin Islands
    }

    &-bn {
      background-image: url('assets/svg/country_flag/bn.svg'); // Brunei
    }

    &-bg {
      background-image: url('assets/svg/country_flag/bg.svg'); // Bulgaria
    }

    &-bf {
      background-image: url('assets/svg/country_flag/bf.svg'); // Burkina Faso
    }

    &-bi {
      background-image: url('assets/svg/country_flag/bi.svg'); // Burundi
    }

    &-kh {
      background-image: url('assets/svg/country_flag/kh.svg'); // Cambodia
    }

    &-cm {
      background-image: url('assets/svg/country_flag/cm.svg'); // Cameroon
    }

    &-ca {
      background-image: url('assets/svg/country_flag/ca.svg'); // Canada
    }

    &-cv {
      background-image: url('assets/svg/country_flag/cv.svg'); // Cape Verde
    }

    &-bq {
      background-image: url('assets/svg/country_flag/bq.svg'); // Caribbean Netherlands
    }

    &-ky {
      background-image: url('assets/svg/country_flag/ky.svg'); // Cayman Islands
      background-position-x: right;
    }

    &-cf {
      background-image: url('assets/svg/country_flag/cf.svg'); // Central African Republic
    }

    &-td {
      background-image: url('assets/svg/country_flag/td.svg'); // Chad
    }

    &-cl {
      background-image: url('assets/svg/country_flag/cl.svg'); // Chile
      background-position-x: left;
    }

    &-cn {
      background-image: url('assets/svg/country_flag/cn.svg'); // China
      background-position-x: left;
      background-position-y: top;
    }

    &-cx {
      background-image: url('assets/svg/country_flag/cx.svg'); // Christmas Island
      background-position-x: right;
    }

    &-cc {
      background-image: url('assets/svg/country_flag/cc.svg'); // Cocos Islands
      background-position-x: left;
    }

    &-co {
      background-image: url('assets/svg/country_flag/co.svg'); // Colombia
    }

    &-km {
      background-image: url('assets/svg/country_flag/km.svg'); // Comoros
      background-position-x: left;
    }

    &-cd {
      background-image: url('assets/svg/country_flag/cd.svg'); // Congo
      background-position-x: left;
    }

    &-cg {
      background-image: url('assets/svg/country_flag/cg.svg'); // Congo
    }

    &-ck {
      background-image: url('assets/svg/country_flag/ck.svg'); // Cook Islands
    }

    &-cr {
      background-image: url('assets/svg/country_flag/cr.svg'); // Costa Rica
    }

    &-ci {
      background-image: url('assets/svg/country_flag/ci.svg'); // Côte d’Ivoire
    }

    &-hr {
      background-image: url('assets/svg/country_flag/hr.svg'); // Croatia
    }

    &-cu {
      background-image: url('assets/svg/country_flag/cu.svg'); // Cuba
      background-position-x: left;
    }

    &-cw {
      background-image: url('assets/svg/country_flag/cw.svg'); // Curaçao
      background-position-x: left;
    }

    &-cy {
      background-image: url('assets/svg/country_flag/cy.svg'); // Cyprus
    }

    &-cz {
      background-image: url('assets/svg/country_flag/cz.svg'); // Czech Republic
    }

    &-dk {
      background-image: url('assets/svg/country_flag/dk.svg'); // Denmark
    }

    &-dj {
      background-image: url('assets/svg/country_flag/dj.svg'); // Djibouti
      background-position-x: left;
    }

    &-dm {
      background-image: url('assets/svg/country_flag/dm.svg'); // Dominica
    }

    &-do {
      background-image: url('assets/svg/country_flag/do.svg'); // Dominican Republic
    }

    &-ec {
      background-image: url('assets/svg/country_flag/ec.svg'); // Ecuador
    }

    &-eg {
      background-image: url('assets/svg/country_flag/eg.svg'); // Egypt
    }

    &-sv {
      background-image: url('assets/svg/country_flag/cv.svg'); // El Salvador
    }

    &-gq {
      background-image: url('assets/svg/country_flag/gq.svg'); // Equatorial Guinea
      background-position-x: left;
    }

    &-er {
      background-image: url('assets/svg/country_flag/er.svg'); // Eritrea
      background-position-x: left;
    }

    &-ee {
      background-image: url('assets/svg/country_flag/ee.svg'); // Estonia
    }

    &-et {
      background-image: url('assets/svg/country_flag/et.svg'); // Ethiopia
    }

    &-fk {
      background-image: url('assets/svg/country_flag/fk.svg'); // Falkland Islands
    }

    &-fo {
      background-image: url('assets/svg/country_flag/fo.svg'); // Faroe Islands
    }

    &-fj {
      background-image: url('assets/svg/country_flag/fj.svg'); // Fiji
    }

    &-fi {
      background-image: url('assets/svg/country_flag/fi.svg'); // Finland
    }

    &-fr {
      background-image: url('assets/svg/country_flag/fr.svg'); // France
    }

    &-gf {
      background-image: url('assets/svg/country_flag/gf.svg'); // French Guiana
    }

    &-pf {
      background-image: url('assets/svg/country_flag/pf.svg'); // French Polynesia
    }

    &-ga {
      background-image: url('assets/svg/country_flag/ga.svg'); // Gabon
    }

    &-gm {
      background-image: url('assets/svg/country_flag/gm.svg'); // Gambia
    }

    &-ge {
      background-image: url('assets/svg/country_flag/ge.svg'); // Georgia
    }

    &-de {
      background-image: url('assets/svg/country_flag/de.svg'); // Germany
    }

    &-gh {
      background-image: url('assets/svg/country_flag/gh.svg'); // Ghana
    }

    &-gi {
      background-image: url('assets/svg/country_flag/gi.svg'); // Gibraltar
    }

    &-gr {
      background-image: url('assets/svg/country_flag/gr.svg'); // Greece
      background-position-x: left;
    }

    &-gl {
      background-image: url('assets/svg/country_flag/gl.svg'); // Greenland
      background-position-x: -1px;
    }

    &-gd {
      background-image: url('assets/svg/country_flag/gd.svg'); // Grenada
    }

    &-gp {
      background-image: url('assets/svg/country_flag/gp.svg'); // Guadeloupe
    }

    &-gu {
      background-image: url('assets/svg/country_flag/gu.svg'); // Guam
    }

    &-gt {
      background-image: url('assets/svg/country_flag/gt.svg'); // Guatemala
    }

    &-gg {
      background-image: url('assets/svg/country_flag/gg.svg'); // Guernsey
    }

    &-gn {
      background-image: url('assets/svg/country_flag/gn.svg'); // Guinea
    }

    &-gw {
      background-image: url('assets/svg/country_flag/gw.svg'); // Guinea-Bissau
      background-position-x: left;
    }

    &-gy {
      background-image: url('assets/svg/country_flag/gy.svg'); // Guyana
      background-position-x: left;
    }

    &-ht {
      background-image: url('assets/svg/country_flag/ht.svg'); // Haiti
    }

    &-hn {
      background-image: url('assets/svg/country_flag/hn.svg'); // Honduras
    }

    &-hk {
      background-image: url('assets/svg/country_flag/hk.svg'); // Hong Kong
    }

    &-hu {
      background-image: url('assets/svg/country_flag/hu.svg'); // Hungary
    }

    &-is {
      background-image: url('assets/svg/country_flag/is.svg'); // Iceland
    }

    &-in {
      background-image: url('assets/svg/country_flag/in.svg'); // India
    }

    &-id {
      background-image: url('assets/svg/country_flag/id.svg'); // Indonesia
    }

    &-ir {
      background-image: url('assets/svg/country_flag/ir.svg'); // Iran
    }

    &-iq {
      background-image: url('assets/svg/country_flag/iq.svg'); // Iraq
    }

    &-ie {
      background-image: url('assets/svg/country_flag/ie.svg'); // Ireland
    }

    &-im {
      background-image: url('assets/svg/country_flag/im.svg'); // Isle of Man
    }

    &-il {
      background-image: url('assets/svg/country_flag/il.svg'); // Israel
    }

    &-it {
      background-image: url('assets/svg/country_flag/it.svg'); // Italy
    }

    &-jm {
      background-image: url('assets/svg/country_flag/jm.svg'); // Jamaica
    }

    &-jp {
      background-image: url('assets/svg/country_flag/jp.svg'); // Japan
    }

    &-je {
      background-image: url('assets/svg/country_flag/je.svg'); // Jersey
    }

    &-jo {
      background-image: url('assets/svg/country_flag/jo.svg'); // Jordan
      background-position-x: left;
    }

    &-kz {
      background-image: url('assets/svg/country_flag/kz.svg'); // Kazakhstan
      background-position-x: left;
    }

    &-ke {
      background-image: url('assets/svg/country_flag/ke.svg'); // Kenya
    }

    &-ki {
      background-image: url('assets/svg/country_flag/ki.svg'); // Kiribati
    }

    &-xk {
      background-image: url('assets/svg/country_flag/xk.svg'); // Kosovo
    }

    &-kw {
      background-image: url('assets/svg/country_flag/kw.svg'); // Kuwait
      background-position-x: left;
    }

    &-kg {
      background-image: url('assets/svg/country_flag/kg.svg'); // Kyrgyzstan
    }

    &-la {
      background-image: url('assets/svg/country_flag/la.svg'); // Laos
    }

    &-lv {
      background-image: url('assets/svg/country_flag/lv.svg'); // Latvia
    }

    &-lb {
      background-image: url('assets/svg/country_flag/lb.svg'); // Lebanon
    }

    &-ls {
      background-image: url('assets/svg/country_flag/ls.svg'); // Lesotho
    }

    &-lr {
      background-image: url('assets/svg/country_flag/lr.svg'); // Liberia
      background-position-x: left;
    }

    &-ly {
      background-image: url('assets/svg/country_flag/ly.svg'); // Libya
    }

    &-li {
      background-image: url('assets/svg/country_flag/li.svg'); // Liechtenstein
      background-position-x: left;
    }

    &-lt {
      background-image: url('assets/svg/country_flag/lt.svg'); // Lithuania
    }

    &-lu {
      background-image: url('assets/svg/country_flag/lu.svg'); // Luxembourg
    }

    &-mo {
      background-image: url('assets/svg/country_flag/mo.svg'); // Macau
    }

    &-mk {
      background-image: url('assets/svg/country_flag/mk.svg'); // Macedonia
    }

    &-mg {
      background-image: url('assets/svg/country_flag/mg.svg'); // Madagascar
      background-position-x: left;
    }

    &-mw {
      background-image: url('assets/svg/country_flag/mw.svg'); // Malawi
    }

    &-my {
      background-image: url('assets/svg/country_flag/my.svg'); // Malaysia
      background-position-x: left;
    }

    &-mv {
      background-image: url('assets/svg/country_flag/mv.svg'); // Maldives
    }

    &-ml {
      background-image: url('assets/svg/country_flag/ml.svg'); // Mali
    }

    &-mt {
      background-image: url('assets/svg/country_flag/mt.svg'); // Malta
      background-position-x: left;
    }

    &-mh {
      background-image: url('assets/svg/country_flag/mh.svg'); // Marshall Islands
      background-position-x: left;
    }

    &-mq {
      background-image: url('assets/svg/country_flag/mq.svg'); // Martinique
      background-position-x: left;
      background-position-y: top;
    }

    &-mr {
      background-image: url('assets/svg/country_flag/mr.svg'); // Mauritania
    }

    &-mu {
      background-image: url('assets/svg/country_flag/mu.svg'); // Mauritius
    }

    &-yt {
      background-image: url('assets/svg/country_flag/yt.svg'); // Mayotte
    }

    &-mx {
      background-image: url('assets/svg/country_flag/mx.svg'); // Mexico
    }

    &-fm {
      background-image: url('assets/svg/country_flag/fm.svg'); // Micronesia
    }

    &-md {
      background-image: url('assets/svg/country_flag/md.svg'); // Moldova
    }

    &-mc {
      background-image: url('assets/svg/country_flag/mc.svg'); // Monaco
    }

    &-mn {
      background-image: url('assets/svg/country_flag/mn.svg'); // Mongolia
    }

    &-me {
      background-image: url('assets/svg/country_flag/me.svg'); // Montenegro
    }

    &-ms {
      background-image: url('assets/svg/country_flag/ms.svg'); // Montserrat
      background-position-x: left;
    }

    &-ma {
      background-image: url('assets/svg/country_flag/ma.svg'); // Morocco
    }

    &-mz {
      background-image: url('assets/svg/country_flag/mz.svg'); // Mozambique
      background-position-x: left;
    }

    &-mm {
      background-image: url('assets/svg/country_flag/mm.svg'); // Myanmar
    }

    &-na {
      background-image: url('assets/svg/country_flag/na.svg'); // Namibia
      background-position-x: left;
    }

    &-nr {
      background-image: url('assets/svg/country_flag/nr.svg'); // Nauru
      background-position-x: left;
    }

    &-np {
      background-image: url('assets/svg/country_flag/np.svg'); // Nepal
      background-position-x: left;
    }

    &-nl {
      background-image: url('assets/svg/country_flag/nl.svg'); // Netherlands
    }

    &-nc {
      background-image: url('assets/svg/country_flag/nc.svg'); // New Caledonia
    }

    &-nz {
      background-image: url('assets/svg/country_flag/nz.svg'); // New Zealand
    }

    &-ni {
      background-image: url('assets/svg/country_flag/ni.svg'); // Nicaragua
    }

    &-ne {
      background-image: url('assets/svg/country_flag/ne.svg'); // Niger
    }

    &-ng {
      background-image: url('assets/svg/country_flag/ng.svg'); // Nigeria
    }

    &-nu {
      background-image: url('assets/svg/country_flag/nu.svg'); // Niue
    }

    &-nf {
      background-image: url('assets/svg/country_flag/nf.svg'); // Norfolk Island
    }

    &-kp {
      background-image: url('assets/svg/country_flag/kp.svg'); // North Korea
    }

    &-mp {
      background-image: url('assets/svg/country_flag/mp.svg'); // Northern Mariana Islands
    }

    &-no {
      background-image: url('assets/svg/country_flag/no.svg'); // Norway
    }

    &-om {
      background-image: url('assets/svg/country_flag/om.svg'); // Oman
      background-position-x: left;
    }

    &-pk {
      background-image: url('assets/svg/country_flag/pk.svg'); // Pakistan
      background-position-x: right;
    }

    &-pw {
      background-image: url('assets/svg/country_flag/pw.svg'); // Palau
    }

    &-ps {
      background-image: url('assets/svg/country_flag/ps.svg'); // Palestine
      background-position-x: left;
    }

    &-pa {
      background-image: url('assets/svg/country_flag/pa.svg'); // Panama
    }

    &-pg {
      background-image: url('assets/svg/country_flag/pg.svg'); // Papua New Guinea
    }

    &-py {
      background-image: url('assets/svg/country_flag/py.svg'); // Paraguay
    }

    &-pe {
      background-image: url('assets/svg/country_flag/pe.svg'); // Peru
    }

    &-ph {
      background-image: url('assets/svg/country_flag/ph.svg'); // Philippines
      background-position-x: 5px;
    }

    &-pl {
      background-image: url('assets/svg/country_flag/pl.svg'); // Poland
    }

    &-pt {
      background-image: url('assets/svg/country_flag/pt.svg'); // Portugal
    }

    &-pr {
      background-image: url('assets/svg/country_flag/pr.svg'); // Puerto Rico
      background-position-x: left;
    }

    &-qa {
      background-image: url('assets/svg/country_flag/qa.svg'); // Qatar
    }

    &-re {
      background-image: url('assets/svg/country_flag/re.svg'); // Réunion
    }

    &-ro {
      background-image: url('assets/svg/country_flag/ro.svg'); // Romania
    }

    &-ru {
      background-image: url('assets/svg/country_flag/ru.svg'); // Russia
    }

    &-rw {
      background-image: url('assets/svg/country_flag/rw.svg'); // Rwanda
      background-position-x: right;
    }

    &-bl {
      background-image: url('assets/svg/country_flag/bl.svg'); // Saint Barthélemy
    }

    &-sh {
      background-image: url('assets/svg/country_flag/sh.svg'); // Saint Helena
    }

    &-kn {
      background-image: url('assets/svg/country_flag/kn.svg'); // Saint Kitts and Nevis
    }

    &-lc {
      background-image: url('assets/svg/country_flag/lc.svg'); // Saint Lucia
    }

    &-mf {
      background-image: url('assets/svg/country_flag/mf.svg'); // Saint Martin
    }

    &-pm {
      background-image: url('assets/svg/country_flag/pm.svg'); // Saint Pierre and Miquelon
    }

    &-vc {
      background-image: url('assets/svg/country_flag/vc.svg'); // Saint Vincent and the Grenadines
    }

    &-ws {
      background-image: url('assets/svg/country_flag/ws.svg'); // Samoa
      background-position-x: left;
    }

    &-sm {
      background-image: url('assets/svg/country_flag/sm.svg'); // San Marino
    }

    &-st {
      background-image: url('assets/svg/country_flag/st.svg'); // São Tomé and Príncipe
    }

    &-sa {
      background-image: url('assets/svg/country_flag/sa.svg'); // Saudi Arabia
    }

    &-sn {
      background-image: url('assets/svg/country_flag/sn.svg'); // Senegal
    }

    &-rs {
      background-image: url('assets/svg/country_flag/rs.svg'); // Serbia
    }

    &-sc {
      background-image: url('assets/svg/country_flag/sc.svg'); // Seychelles
    }

    &-sl {
      background-image: url('assets/svg/country_flag/sl.svg'); // Sierra Leone
    }

    &-sg {
      background-image: url('assets/svg/country_flag/sg.svg'); // Singapore
      background-position-x: left;
    }

    &-sx {
      background-image: url('assets/svg/country_flag/sx.svg'); // Sint Maarten
      background-position-x: left;
    }

    &-sk {
      background-image: url('assets/svg/country_flag/sk.svg'); // Slovakia
      background-position-x: -1px;
    }

    &-si {
      background-image: url('assets/svg/country_flag/si.svg'); // Slovenia
      background-position-x: left;
    }

    &-sb {
      background-image: url('assets/svg/country_flag/sb.svg'); // Solomon Islands
      background-position-x: left;
    }

    &-so {
      background-image: url('assets/svg/country_flag/so.svg'); // Somalia
    }

    &-za {
      background-image: url('assets/svg/country_flag/za.svg'); // South Africa
      background-position-x: left;
    }

    &-kr {
      background-image: url('assets/svg/country_flag/kr.svg'); // South Korea
    }

    &-ss {
      background-image: url('assets/svg/country_flag/ss.svg'); // South Sudan
    }

    &-es {
      background-image: url('assets/svg/country_flag/es.svg'); // Spain
      background-position-x: left;
    }

    &-lk {
      background-image: url('assets/svg/country_flag/lk.svg'); // Sri Lanka
    }

    &-sd {
      background-image: url('assets/svg/country_flag/sd.svg'); // Sudan
      background-position-x: left;
    }

    &-sr {
      background-image: url('assets/svg/country_flag/sr.svg'); // Suriname
    }

    &-sj {
      background-image: url('assets/svg/country_flag/sj.svg'); // Svalbard and Jan Mayen
    }

    &-sz {
      background-image: url('assets/svg/country_flag/sz.svg'); // Swaziland
    }

    &-se {
      background-image: url('assets/svg/country_flag/se.svg'); // Sweden
    }

    &-ch {
      background-image: url('assets/svg/country_flag/ch.svg'); // Switzerland
    }

    &-sy {
      background-image: url('assets/svg/country_flag/sy.svg'); // Syria
    }

    &-tw {
      background-image: url('assets/svg/country_flag/tw.svg'); // Taiwan
      background-position-x: left;
    }

    &-tj {
      background-image: url('assets/svg/country_flag/tj.svg'); // Tajikistan
    }

    &-tz {
      background-image: url('assets/svg/country_flag/tz.svg'); // Tanzania
    }

    &-th {
      background-image: url('assets/svg/country_flag/th.svg'); // Thailand
    }

    &-tl {
      background-image: url('assets/svg/country_flag/tl.svg'); // Timor-Leste
      background-position-x: left;
    }

    &-tg {
      background-image: url('assets/svg/country_flag/tg.svg'); // Togo
      background-position-x: left;
    }

    &-tk {
      background-image: url('assets/svg/country_flag/tk.svg'); // Tokelau
    }

    &-to {
      background-image: url('assets/svg/country_flag/to.svg'); // Tonga
      background-position-x: left;
    }

    &-tt {
      background-image: url('assets/svg/country_flag/tt.svg'); // Trinidad and Tobago
    }

    &-tn {
      background-image: url('assets/svg/country_flag/tn.svg'); // Tunisia
    }

    &-tr {
      background-image: url('assets/svg/country_flag/tr.svg'); // Turkey
    }

    &-tm {
      background-image: url('assets/svg/country_flag/tm.svg'); // Turkmenistan
    }

    &-tc {
      background-image: url('assets/svg/country_flag/tc.svg'); // Turks and Caicos Islands
    }

    &-tv {
      background-image: url('assets/svg/country_flag/tv.svg'); // Tuvalu
    }

    &-vi {
      background-image: url('assets/svg/country_flag/vi.svg'); // U.S. Virgin Islands
    }

    &-ug {
      background-image: url('assets/svg/country_flag/ug.svg'); // Uganda
    }

    &-ua {
      background-image: url('assets/svg/country_flag/ua.svg'); // Ukraine
    }

    &-ae {
      background-image: url('assets/svg/country_flag/ae.svg'); // United Arab Emirates
      background-position-x: left;
    }

    &-gb {
      background-image: url('assets/svg/country_flag/gb.svg'); // United Kingdom
    }

    &-us {
      background-image: url('assets/svg/country_flag/us.svg'); // United States
      background-position-x: left;
    }

    &-uy {
      background-image: url('assets/svg/country_flag/uy.svg'); // Uruguay
      background-position-x: left;
    }

    &-uz {
      background-image: url('assets/svg/country_flag/uz.svg'); // Uzbekistan
      background-position-x: left;
    }

    &-vu {
      background-image: url('assets/svg/country_flag/vu.svg'); // Vanuatu
      background-position-x: left;
    }

    &-va {
      background-image: url('assets/svg/country_flag/va.svg'); // Vatican City
    }

    &-ve {
      background-image: url('assets/svg/country_flag/ve.svg'); // Venezuela
    }

    &-vn {
      background-image: url('assets/svg/country_flag/vn.svg'); // Vietnam
    }

    &-wf {
      background-image: url('assets/svg/country_flag/wf.svg'); // Wallis and Futuna
    }

    &-eh {
      background-image: url('assets/svg/country_flag/eh.svg'); // Western Sahara
    }

    &-ye {
      background-image: url('assets/svg/country_flag/ye.svg'); // Yemen
    }

    &-zm {
      background-image: url('assets/svg/country_flag/zm.svg'); // Zambia
      background-position-x: right;
    }

    &-zw {
      background-image: url('assets/svg/country_flag/zw.svg'); // Zimbabwe
      background-position-x: left;
    }

    &-ax {
      background-image: url('assets/svg/country_flag/ax.svg'); // Åland Islands
    }

    &-bv {
      background-image: url('assets/svg/country_flag/bv.svg'); // Bouvet Island
    }

    &-aq {
      background-image: url('assets/svg/country_flag/aq.svg'); // Antarctica
    }

    &-tf {
      background-image: url('assets/svg/country_flag/tf.svg'); // French Southern Territories
    }

    &-hm {
      background-image: url('assets/svg/country_flag/hm.svg'); // Heard Island and Mcdonald Islands
    }

    &-an {
      background-image: url('assets/svg/country_flag/an.svg'); // Netherlands Antilles
    }

    &-pn {
      background-image: url('assets/svg/country_flag/pn.svg'); // Pitcairn
    }

    &-gs {
      background-image: url('assets/svg/country_flag/gs.svg'); // South Georgia and the South Sandwich Islands
    }

    &-um {
      background-image: url('assets/svg/country_flag/um.svg'); // United States Minor Outlying Islands
    }
  }

  // languages

  &-lang {
    &-aa {
      background-image: url('assets/svg/lang_flag/aa.svg');
      background-position-x: left;
    }

    &-ab {
      background-image: url('assets/svg/lang_flag/ab.svg');
    }

    &-af {
      background-image: url('assets/svg/lang_flag/af.svg');
      background-position-x: left;
    }

    &-an {
      background-image: url('assets/svg/lang_flag/an.svg');
    }

    &-arz {
      background-image: url('assets/svg/country_flag/eg.svg');
    }

    &-arb {
      background-image: url('assets/svg/lang_flag/arb.svg'); // Arabic
      background-position-x: left;
    }

    &-ast {
      background-image: url('assets/svg/lang_flag/ast.svg');
      background-position-x: left;
    }

    &-als {
      background-image: url('assets/svg/lang_flag/als.svg');
    }

    &-am {
      background-image: url('assets/svg/lang_flag/am.svg');
    }

    &-as {
      background-image: url('assets/svg/lang_flag/as.svg');
    }

    &-ay {
      background-image: url('assets/svg/lang_flag/ay.svg');
    }

    &-apd {
      background-image: url('assets/svg/lang_flag/apd.svg');
      background-position-x: left;
    }

    &-az {
      background-image: url('assets/svg/lang_flag/az.svg');
    }

    &-ba {
      background-image: url('assets/svg/lang_flag/ba.svg');
    }

    &-bpy {
      background-image: url('assets/svg/lang_flag/rn.svg');
    }

    &-bar {
      background-image: url('assets/svg/lang_flag/de.svg');
    }

    &-be {
      background-image: url('assets/svg/lang_flag/be.svg');
      background-position-x: left;
    }

    &-bg {
      background-image: url('assets/svg/lang_flag/bg.svg');
    }

    &-bi {
      background-image: url('assets/svg/lang_flag/bi.svg');
      background-position-x: left;
    }

    &-bn {
      background-image: url('assets/svg/lang_flag/bn.svg');
    }

    &-bo {
      background-image: url('assets/svg/lang_flag/bo.svg');
    }

    &-bho {
      background-image: url('assets/svg/lang_flag/bho.svg');
    }

    &-br {
      background-image: url('assets/svg/lang_flag/br.svg');
      background-position-x: left;
    }

    &-ca {
      background-image: url('assets/svg/lang_flag/ca.svg');
    }

    &-co {
      background-image: url('assets/svg/lang_flag/co.svg');
    }

    &-ce {
      background-image: url('assets/svg/lang_flag/ce.svg');
    }

    &-ceb {
      background-image: url('assets/svg/lang_flag/tl.svg');
      background-position-x: left;
    }

    &-cs {
      background-image: url('assets/svg/lang_flag/cs.svg');
      background-position-x: left;
    }

    &-cy {
      background-image: url('assets/svg/lang_flag/cy.svg');
    }

    &-cv {
      background-image: url('assets/svg/lang_flag/cv.svg');
    }

    &-cnr {
      background-image: url('assets/svg/country_flag/me.svg');
    }

    &-qcn {
      background-image: url('assets/svg/lang_flag/qcn.svg');
      background-position-x: left;
    }

    &-lld {
      background-image: url('assets/svg/lang_flag/lld.svg');
      background-position-x: left;
    }

    &-hbs {
      background-image: url('assets/svg/lang_flag/hbs.svg');
      background-position-x: left;
    }

    &-cmn {
      background-image: url('assets/svg/lang_flag/cmn.svg');
      background-position-x: left;
    }

    &-tlh-latn,
    &-tlh-piqd {
      background-image: url('assets/svg/lang_flag/tlh-latn.svg');
    }

    &-da {
      background-image: url('assets/svg/lang_flag/da.svg');
    }

    &-de,
    &-hsb,
    &-vls {
      background-image: url('assets/svg/lang_flag/de.svg');
    }

    &-dv {
      background-image: url('assets/svg/country_flag/mv.svg');
    }

    &-diq {
      background-image: url('assets/svg/lang_flag/tr.svg');
    }

    &-vec {
      background-image: url('assets/svg/lang_flag/it.svg');
    }

    &-el {
      background-image: url('assets/svg/lang_flag/el.svg');
      background-position-x: left;
    }

    &-eo {
      background-image: url('assets/svg/lang_flag/eo.svg');
      background-position-x: left;
    }

    &-es {
      background-image: url('assets/svg/lang_flag/es.svg');
      background-position-x: left;
    }

    &-eu {
      background-image: url('assets/svg/lang_flag/eu.svg');
    }

    &-fa {
      background-image: url('assets/svg/lang_flag/fa.svg');
    }

    &-fi {
      background-image: url('assets/svg/lang_flag/fi.svg');
    }

    &-fj {
      background-image: url('assets/svg/lang_flag/fj.svg');
    }

    &-fo {
      background-image: url('assets/svg/lang_flag/fo.svg');
    }

    &-fy {
      background-image: url('assets/svg/lang_flag/fy.svg');
    }

    &-ga {
      background-image: url('assets/svg/lang_flag/ga.svg');
    }

    &-li {
      background-image: url('assets/svg/lang_flag/li.svg');
      background-position-x: left;
    }

    &-gan {
      background-image: url('assets/svg/lang_flag/cmn.svg');
      background-position-x: left;
    }

    &-gd,
    &-sco {
      background-image: url('assets/svg/lang_flag/gd.svg');
    }

    &-gl {
      background-image: url('assets/svg/lang_flag/gl.svg');
    }

    &-gn {
      background-image: url('assets/svg/lang_flag/gn.svg');
    }

    &-gu {
      background-image: url('assets/svg/lang_flag/gu.svg');
    }

    &-ha {
      background-image: url('assets/svg/lang_flag/ha.svg');
    }

    &-he {
      background-image: url('assets/svg/lang_flag/he.svg');
    }

    &-hi {
      background-image: url('assets/svg/lang_flag/hi.svg');
    }

    &-hif {
      background-image: url('assets/svg/lang_flag/fj.svg');
    }

    &-hr {
      background-image: url('assets/svg/lang_flag/hr.svg');
    }

    &-hu {
      background-image: url('assets/svg/lang_flag/hu.svg');
    }

    &-hy {
      background-image: url('assets/svg/lang_flag/hy.svg');
    }

    &-ia {
      background-image: url('assets/svg/lang_flag/ia.svg');
    }

    &-id {
      background-image: url('assets/svg/lang_flag/id.svg');
    }

    &-ie {
      background-image: url('assets/svg/lang_flag/ie.svg');
    }

    &-io {
      background-image: url('assets/svg/lang_flag/io.svg');
    }

    &-ilo {
      background-image: url('assets/svg/lang_flag/tl.svg');
      background-position-x: left;
    }

    &-ik {
      background-image: url('assets/svg/lang_flag/ik.svg');
    }

    &-is {
      background-image: url('assets/svg/lang_flag/is.svg');
    }

    &-it {
      background-image: url('assets/svg/lang_flag/it.svg');
    }

    &-jv {
      background-image: url('assets/svg/lang_flag/jv.svg');
    }

    &-ka {
      background-image: url('assets/svg/lang_flag/ka.svg');
    }

    &-kk {
      background-image: url('assets/svg/lang_flag/kk.svg');
    }

    &-kok {
      background-image: url('assets/svg/lang_flag/gu.svg');
    }

    &-km {
      background-image: url('assets/svg/lang_flag/km.svg');
    }

    &-kn {
      background-image: url('assets/svg/lang_flag/kn.svg');
    }

    &-ko {
      background-image: url('assets/svg/lang_flag/ko.svg');
    }

    &-ks {
      background-image: url('assets/svg/lang_flag/ks.svg');
    }

    &-ku {
      background-image: url('assets/svg/lang_flag/ku.svg');
    }

    &-ky {
      background-image: url('assets/svg/lang_flag/ky.svg');
    }

    &-la {
      background-image: url('assets/svg/lang_flag/la.svg');
    }

    &-ln {
      background-image: url('assets/svg/lang_flag/ln.svg');
    }

    &-lb {
      background-image: url('assets/svg/lang_flag/lb.svg');
    }

    &-gv {
      background-image: url('assets/svg/country_flag/im.svg');
    }

    &-lo {
      background-image: url('assets/svg/lang_flag/lo.svg');
    }

    &-lmo {
      background-image: url('assets/svg/lang_flag/it.svg');
    }

    &-lt {
      background-image: url('assets/svg/lang_flag/lt.svg');
    }

    &-lv {
      background-image: url('assets/svg/lang_flag/lv.svg');
    }

    &-mg {
      background-image: url('assets/svg/lang_flag/mg.svg');
    }

    &-mt {
      background-image: url('assets/svg/lang_flag/mt.svg');
      background-position-x: left;
    }

    &-mi {
      background-image: url('assets/svg/lang_flag/mi.svg');
    }

    &-mk {
      background-image: url('assets/svg/lang_flag/mi.svg');
    }

    &-mi {
      background-image: url('assets/svg/lang_flag/mk.svg');
    }

    &-mn {
      background-image: url('assets/svg/lang_flag/mn.svg');
    }

    &-mr {
      background-image: url('assets/svg/lang_flag/mr.svg');
    }

    &-my {
      background-image: url('assets/svg/lang_flag/my.svg');
      background-position-x: left;
    }

    &-na {
      background-image: url('assets/svg/lang_flag/na.svg');
    }

    &-ne {
      background-image: url('assets/svg/lang_flag/ne.svg');
      background-position-x: left;
    }

    &-no,
    &-nn {
      background-image: url('assets/svg/lang_flag/ng.svg');
    }

    &-ng {
      background-image: url('assets/svg/lang_flag/ng.svg');
    }

    &-nl {
      background-image: url('assets/svg/lang_flag/nl.svg');
    }

    &-oc {
      background-image: url('assets/svg/lang_flag/oc.svg');
    }

    &-om {
      background-image: url('assets/svg/lang_flag/om.svg');
    }

    &-or,
    &-os {
      background-image: url('assets/svg/lang_flag/or.svg');
    }

    &-pl {
      background-image: url('assets/svg/lang_flag/pl.svg');
    }

    &-ps {
      background-image: url('assets/svg/lang_flag/ps.svg');
    }

    &-pms {
      background-image: url('assets/svg/lang_flag/it.svg');
    }

    &-pam {
      background-image: url('assets/svg/lang_flag/tl.svg');
      background-position-x: left;
    }

    &-sah {
      background-image: url('assets/svg/lang_flag/sah.svg');
      background-position-x: left;
    }

    &-pt,
    &-pt-pt {
      background-image: url('assets/svg/lang_flag/pt.svg');
    }

    &-qu {
      background-image: url('assets/svg/lang_flag/qu.svg');
    }

    &-ro {
      background-image: url('assets/svg/lang_flag/ro.svg'); // Romania
    }

    &-rn {
      background-image: url('assets/svg/lang_flag/rn.svg');
    }

    &-rm {
      background-image: url('assets/svg/lang_flag/rm.svg');
    }

    &-rw {
      background-image: url('assets/svg/lang_flag/rw.svg');
    }

    &-sa {
      background-image: url('assets/svg/lang_flag/sa.svg');
    }

    &-se {
      background-image: url('assets/svg/lang_flag/se.svg');
      background-position-x: -5px;
    }

    &-sr,
    &-sr-cyrl,
    &-sr-latn {
      background-image: url('assets/svg/lang_flag/sr.svg');
    }

    &-sd {
      background-image: url('assets/svg/lang_flag/sd.svg');
    }

    &-sg {
      background-image: url('assets/svg/lang_flag/sg.svg');
    }

    &-su {
      background-image: url('assets/svg/country_flag/sl.svg');
    }

    &-scn {
      background-image: url('assets/svg/lang_flag/scn.svg');
    }

    &-szl {
      background-image: url('assets/svg/lang_flag/pl.svg');
    }

    &-sh {
      background-image: url('assets/svg/lang_flag/sh.svg');
    }

    &-si {
      background-image: url('assets/svg/lang_flag/si.svg');
    }

    &-sk {
      background-image: url('assets/svg/lang_flag/sk.svg');
      background-position-x: -1px !important;
    }

    &-sl {
      background-image: url('assets/svg/lang_flag/sl.svg');
    }

    &-sm {
      background-image: url('assets/svg/lang_flag/sm.svg');
      background-position-x: left;
    }

    &-sn {
      background-image: url('assets/svg/lang_flag/sn.svg');
      background-position-x: left;
    }

    &-so {
      background-image: url('assets/svg/lang_flag/so.svg');
    }

    &-ss {
      background-image: url('assets/svg/lang_flag/ss.svg');
    }

    &-st {
      background-image: url('assets/svg/lang_flag/st.svg');
    }

    &-sv {
      background-image: url('assets/svg/lang_flag/sv.svg');
    }

    &-sw {
      background-image: url('assets/svg/lang_flag/sw.svg');
    }

    &-sq {
      background-image: url('assets/svg/lang_flag/als.svg');
    }

    &-tr {
      background-image: url('assets/svg/lang_flag/als.svg');
    }

    &-ta {
      background-image: url('assets/svg/lang_flag/ta.svg');
    }

    &-te {
      background-image: url('assets/svg/lang_flag/te.svg');
    }

    &-tg {
      background-image: url('assets/svg/lang_flag/tg.svg');
    }

    &-th {
      background-image: url('assets/svg/lang_flag/th.svg');
    }

    &-ti {
      background-image: url('assets/svg/lang_flag/ti.svg');
    }

    &-tk {
      background-image: url('assets/svg/lang_flag/tk.svg');
    }

    &-tl,
    &-war {
      background-image: url('assets/svg/lang_flag/tl.svg');
      background-position-x: left;
    }

    &-tr {
      background-image: url('assets/svg/lang_flag/tr.svg');
    }

    &-tn {
      background-image: url('assets/svg/lang_flag/tn.svg');
    }

    &-to {
      background-image: url('assets/svg/lang_flag/to.svg');
      background-position-x: left;
    }

    &-ts {
      background-image: url('assets/svg/lang_flag/ts.svg');
    }

    &-tt {
      background-image: url('assets/svg/lang_flag/tt.svg');
    }

    &-tw {
      background-image: url('assets/svg/lang_flag/tw.svg');
    }

    &-uk {
      background-image: url('assets/svg/lang_flag/uk.svg');
    }

    &-ur {
      background-image: url('assets/svg/lang_flag/ur.svg');
      background-position-x: left;
    }

    &-ug {
      background-image: url('assets/svg/lang_flag/ug.svg');
    }

    &-uz {
      background-image: url('assets/svg/lang_flag/uz.svg');
    }

    &-vi {
      background-image: url('assets/svg/lang_flag/vi.svg');
    }

    &-vo {
      background-image: url('assets/svg/lang_flag/vo.svg');
      background-position-x: left;
    }

    &-wo {
      background-image: url('assets/svg/lang_flag/wo.svg');
    }

    &-wa {
      background-image: url('assets/svg/lang_flag/wa.svg');
    }

    &-xh {
      background-image: url('assets/svg/lang_flag/xh.svg');
    }

    &-yi {
      background-image: url('assets/svg/lang_flag/yi.svg');
    }

    &-yo {
      background-image: url('assets/svg/lang_flag/yo.svg');
    }

    &-zu {
      background-image: url('assets/svg/lang_flag/zu.svg');
      background-position-x: left;
    }

    &-en {
      background-image: url('assets/svg/country_flag/gb.svg'); // English
    }

    &-zh {
      background-image: url('assets/svg/lang_flag/cmn.svg'); // Chinese Traditional
      background-position-x: left;
      background-position-y: top;
    }

    &-ja {
      background-image: url('assets/svg/lang_flag/ja.svg'); // Japanese
    }

    &-fr {
      background-image: url('assets/svg/lang_flag/fr.svg'); // French
    }

    &-fr-ca {
      background-image: url('assets/svg/lang_flag/fr.svg'); // French (Canada)
    }

    &-ar {
      background-image: url('assets/svg/lang_flag/ar.svg');
      background-position-x: left;
    }

    &-bs {
      background-image: url('assets/svg/country_flag/ba.svg');
    }

    &-fil {
      background-image: url('assets/svg/lang_flag/tl.svg');
      background-position-x: left;
    }

    &-ht {
      background-image: url('assets/svg/lang_flag/ht.svg');
    }

    &-kmr {
      background-image: url('assets/svg/lang_flag/kmr.svg');
    }

    &-ml {
      background-image: url('assets/svg/country_flag/ml.svg');
    }

    &-ms {
      background-image: url('assets/svg/country_flag/ms.svg');
      background-position-x: left;
    }

    // &-mw {
    //   background-image: url('assets/svg/lang_flag/mw.svg');
    // }

    &-mww {
      background-image: url('assets/svg/lang_flag/mww.svg');
    }

    &-nb {
      background-image: url('assets/svg/lang_flag/ng.svg');
    }

    &-otq {
      background-image: url('assets/svg/lang_flag/otq.svg');
    }

    &-pa {
      background-image: url('assets/svg/lang_flag/pa.svg');
    }

    &-prs {
      background-image: url('assets/svg/lang_flag/prs.svg');
    }

    &-ty {
      background-image: url('assets/svg/lang_flag/ty.svg');
    }

    &-yua {
      background-image: url('assets/svg/lang_flag/yua.svg');
    }

    &-et {
      background-image: url('assets/svg/lang_flag/et.svg');
    }

    &-yue,
    &-zh-hans,
    &-zh-hant {
      background-image: url('assets/svg/lang_flag/cmn.svg');
      background-position-x: left;
    }

    &-ru {
      background-image: url('assets/svg/lang_flag/ru.svg');
    }
  }
}

/* special fixes */

.flag-icon-circle.flag-icon-country-ba {
  background-position-x: -12px;
}

.flag-icon-square.flag-icon-country-ba {
  background-position-x: -10px;
}

.flag-icon-circle.flag-icon-country-et,
.flag-icon-square.flag-icon-country-et {
  background-position-x: -9px;
}

.flag-icon-circle.flag-icon-country-bd,
.flag-icon-square.flag-ico-country-bd {
  background-position-x: -6px;
}

.flag-icon-circle.flag-icon-country-me {
  background-size: 180%;
}

.flag-icon-circle.flag-icon-country-pw,
.flag-icon-square.flag-icon-country-pw {
  background-position-x: -6px;
}

.flag-icon-circle.flag-icon-country-je,
.flag-icon-square.flag-icon-country-je {
  background-position-x: -10px;
}

.flag-icon-circle.flag-icon-country-gi,
.flag-icon-square.flag-icon-country-gi {
  background-position-x: -7px;
}

.flag-icon-square.flag-icon-lang-ur {
  background-position-x: -10px;
}

.flag-icon-square.flag-icon-lang-bo {
  background-position-x: -10px;
}

.flag-icon-square.flag-icon-lang-yo {
  background-position-x: 0px;
}

.flag-icon-square.flag-icon-lang-ug {
  background-position-x: -4px;
}
