.table {
  overflow: auto;

  width: calc(100% - 33px);

  margin: 16px 16px 0 16px;
  background: var(--stoked-gray);
  border: 1px solid var(--stroke-gray);

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;

    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;

      color: var(--table-gray);
    }

    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: var(--table-gray);
    }

    svg {
      width: 120px;
      height: 120px;

      margin: 12px 0;
    }

    .clear {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      border: none;

      color: var(--table-gray);

      span {
        text-decoration-line: underline;
      }
    }
  }

  .ant-table-thead .ant-table-cell {
    height: 34px;
  }

  .ant-checkbox-inner {
    border: 2px solid var(--table-gray);
  }

  .ant-table-container {
    &::before,
    &::after {
      box-shadow: none !important;
    }
  }

  .ant-table-selection {
    .ant-table-selection-extra {
      display: none;
    }
  }

  .ant-table-selection-column {
    padding: 0 !important;
  }

  &:active {
    background: var(--background-blue);
  }
}

.ant-table-cell {
  padding: 0 8px !important;
  height: 82px;
  // border: 1px solid var(--stroke-gray);

  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  &:before {
    background-color: var(--stroke-gray) !important;
    height: 100% !important;

    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  * {
    color: var(--table-gray);
  }
}

.ant-table-cell-scrollbar:before {
  right: 1px !important;
}
