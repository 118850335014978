:root {
  --background-white: #ffffff;
  --background-gray-light: rgba(251, 250, 250, 0.5);
  --background-gray: #f3f3f3;
  --background-blue-light: #e4f2fe;
  --background-blue: #d3e4f2;

  --main-blue: #4285eb;
  --main-gray: #33353a;
  --main-black: #000000;

  --table-gray: #6f6f6f;
  --disable-gray: #979797;

  --stroke-gray: #dadada;
  --stroke-blue: #6fb4fc;

  --status-green: #6dcc8d;
  --status-orange: #ffad62;
  --status-red: #ee7886;

  --tags-light-gray: #e5e5e3;
  --tags-dark-gray: #d2d2d1;
  --tags-brown: #dbd4d1;
  --tags-red: #f8d3d3;
  --tags-orange: #f6ddc5;
  --tags-yellow: #f8ecc3;
  --tags-pink: #edcce0;
  --tags-purple: #dbd1e9;
  --tags-blue: #d0e3ec;
  --tags-green: #c5e0dc;

  --tags-light-gray-active: #b8b8b7;
  --tags-dark-gray-active: #8f8f8f;
  --tags-brown-active: #e39566;
  --tags-red-active: #f96464;
  --tags-orange-active: #f8ac63;
  --tags-yellow-active: #f9e495;
  --tags-pink-active: #ed8ec8;
  --tags-purple-active: #b38ce9;
  --tags-blue-active: #60c4f0;
  --tags-green-active: #5ae0cc;
}
