.active-filter {
  display: flex;
  height: auto;
  margin: 16px 16px 0 16px;
  align-items: center;
  width: calc(100% - 48px);
  flex-wrap: wrap;

  &__title {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding-right: 12px;
    color: var(--table-gray);

    text-transform: capitalize;
  }

  &__item {
    &-title {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      padding: 0 4px;

      margin: 2px 0;

      color: var(--table-gray);
      text-transform: capitalize;
    }
    &-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;

      margin: 2px 2px 2px 0;

      background: var(--background-gray);
      border-radius: 26px;
      border-color: var(--background-gray);
    }
  }

  &__option {
    &-title {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;

      color: var(--main-gray);
      text-transform: capitalize;
    }

    &-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;

      border-radius: 26px;
      border-color: none;

      svg {
        color: var(--main-gray);
      }
    }
  }
}
